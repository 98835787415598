<template>
  <div class="page-container" style="backgroud: #f6f7f6">
    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
    >
      <div style="text-align: center">
        <span>此专区栏目信息展示为收费项目</span>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <headerTab></headerTab>

    <div class="page-outter drug-outter">
<!--      <div id="heander"><span>此专区栏目信息展示为收费项目</span></div>-->
      <!--1-->
      <!-- <div class="p-d-top">
        <span>Hi，欢迎光临九医云网上药店</span>
        <span>
          <span @click.stop="toLogin">请登录/免费注册 | </span>
          <span @click.stop="myCollection">我的收藏 | </span>
          <span @click.stop="myOrder">我的订单</span>
        </span>
      </div>
      <el-row type="flex" class="row-bg" justify="end">
        <div class="grid-content bg-purple">
          <el-input placeholder="请输入商品" v-model="searchVal">
            <i slot="prefix" class="el-input__icon el-icon-search"></i>
            <el-button slot="append" type="primary" class="search-btn"
              >搜索</el-button
            >
          </el-input>
        </div>

        <div class="bg-p-d1" @click="myShoppingCart">
          <img class="bg-purple-img" src="../../assets/drugImgs/gouwuche.png" />
          <span class="bg-purple-s1">我的购物车</span>
        </div>
      </el-row> -->
      <!--2-->

      <el-menu
        :default-active="activeIndex"
        class="el-menu-demo-drug"
        active-text-color="#1890FF"
        mode="horizontal"
        @select="handleSelect"
      >
        <el-submenu index="1">
          <template slot="title">全部商品</template>
          <el-menu-item index="1-1" @click="column">首页</el-menu-item>
          <el-menu-item index="1-2" @click="column">家庭常备</el-menu-item>
          <el-menu-item index="1-3" @click="column">调理滋补</el-menu-item>
          <el-menu-item index="1-4" @click="column">男性专区</el-menu-item>
          <el-menu-item index="1-5" @click="column">女性专区</el-menu-item>
          <el-menu-item index="1-6" @click="heradertitle"
            >老人专区</el-menu-item
          >
          <el-menu-item index="1-7" @click="heradertitle"
            >儿童专区</el-menu-item
          >
        </el-submenu>
        <el-menu-item index="2" @click="column">首页</el-menu-item>
        <el-menu-item index="3" @click="column">家庭常备</el-menu-item>
        <el-menu-item index="4" @click="column">调理滋补</el-menu-item>
        <el-menu-item index="5" @click="column">男性专区</el-menu-item>
        <el-menu-item index="6" @click="column">女性专区</el-menu-item>
        <el-menu-item index="7" @click="heradertitle"
          >老人专区(收费栏目)</el-menu-item
        >
        <el-menu-item index="8" @click="heradertitle"
          >儿童专区(收费栏目)</el-menu-item
        >
      </el-menu>
    </div>
    <div class="drugInfo-line"></div>
    <!--内容区域-->
    <router-view :key="key"></router-view>
    <nav-footer></nav-footer>
  </div>
</template>

<script>
import drugs from "./curd/drugs";
import headerTab from "@/components/headerTab";
export default {
  name: "drugInfo",
  data() {
    return {
      searchVal: "",
      activeIndex: "1-1",
      drugs: drugs,
      dialogVisible: false,
    };
  },
  components: {
    headerTab,
    "nav-footer": () => import("@/components/navFooter"),
  },
  computed: {
    key() {
      return this.$route.path;
    },
  },
  methods: {
    column() {
      document.getElementById("heander");
    // .style.display = "none"
    },
    heradertitle() {
      document.getElementById("heander");
    // .style.display = "inline"
      this.dialogVisible = true;
    },
    toLogin() {
      this.$router.push("/login");
    },
    myCollection() {
      this.$router.push("/login");
    },
    myOrder() {
      this.$router.push("/login");
    },
    myShoppingCart() {
      this.$router.push("/login");
    },
    handleSelect(index) {
      switch (index) {
        case "2":
        case "1-1":
          this.$router.push(`/drug-info/first`);
          break;
        case "3":
        case "1-2":
          this.$router.push(`/drug-info/list/家庭常备`);
          break;
        case "4":
        case "1-3":
          this.$router.push(`/drug-info/list/调理滋补`);
          break;
        case "5":
        case "1-4":
          this.$router.push(`/drug-info/list/男性专区`);
          break;
        case "6":
        case "1-5":
          this.$router.push(`/drug-info/list/女性专区`);
          break;
        case "7":
        case "1-6":
          this.$router.push(`/drug-info/list/老人专区`);
          break;
        case "8":
        case "1-7":
          this.$router.push(`/drug-info/list/儿童专区`);
          break;
        case "9":
        case "1-8":
          this.$router.push(`/drug-info/list/医疗器械`);
          break;
        default:
          this.$router.push(`/drug-info/first`);
          break;
      }
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          done();
        })
        .catch((_) => {});
    },
  },
};
</script>

<style lang="less" scoped>
.drug-outter {
  position: relative;
  border-top: 1px solid #fff;
  margin-top: 60px;
}
.p-d-top {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  padding: 10px 0px;
  span {
    cursor: pointer;
  }
}
.search-btn {
  background-color: #3f9dff !important;
  color: #fff !important;
  border-radius: 0px;
}
.drugInfo-line {
  width: 100%;
  height: 3px;
  background: #1890ff;
}
.bg-purple {
  width: 60%;
}
.bg-p-d1 {
  cursor: pointer;
  width: 100px;
  display: flex;
  justify-content: flex-start;
  margin-left: 20px;
}
.bg-purple-img {
  width: 26px;
  height: 26px;
  margin-top: 10px;
}
.bg-purple-s1 {
  display: inline-block;
  font-size: 14px;
  color: #bebfbe;
  margin-top: 15px;
}
.el-menu-demo-drug {
  /*width: 1063.44px;*/
  border-style: none !important;
  margin-top: 20px;
}
.cont-d1 {
  background-repeat: no-repeat;
  background-size: cover;
  height: 400px;
}
.drug-c-title {
  border-bottom: 3px solid #1890ff;
  img {
    height: 19px;
  }
}
.drug-c-c {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.drug-c-item {
  position: relative;
  width: 220px;
  height: 370px;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
  margin-top: 15px;
  img {
    width: 100%;
  }
  .drug-label {
    width: 180px;
    font-size: 13px;
    line-height: 1.5;
    margin: 0 auto;
  }
  .drug-mm {
    position: absolute;
    width: 200px;
    bottom: 64px;
    display: flex;
    justify-content: space-between;
    span {
      display: block;
    }
    .s1 {
      font-size: 23px;
      color: #e02020;
    }
    .s2 {
      font-size: 13px;
      margin-top: 10px;
      color: #838383;
    }
  }
  .drug-submit {
    position: absolute;
    width: 170px;
    border: 1px solid #fa6400;
    color: #fa6400;
    font-size: 13px;
    text-align: center;
    padding: 7px 13px;
    bottom: 26px;
    cursor: pointer;
  }
}
.drug-c-dec {
  padding: 2px 12px 12px 12px;
}
#heander {
  color: black;
  font-size: 20px;
  display: none;
  width: 100%;
  margin-left: 39%;
  height: 100px;
}
</style>
